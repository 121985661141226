export const ST = `%c
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKOXMMMMMMMMMMMMMMMMMMMMKONMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKd,:KMMMMMMMMMMMMMMMMMMMMk,:kNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKd::,:KMMMMMMMMKO0NMMMMMMMMk';::kNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKd,'xKc;KMMMMMMMWd;lOMMMMMMMMk,dXc.:kNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMWKo:c;,OX:;KMMMMMKOXo,cOKOXMMMMMk,xWd':::kNMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMWKd,'dXo,OX:;KMMN0Xk;ll;ld::K00WMMk,xWd,kKc':kNMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMWKo;'',:o:,OX:;KMMNl:d:;:;cc,lo;xWMMk,xWd'll;'',:kNMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMWKo;cOo,dOc''kXc;KMMM0;;;''','':,lNMMMk,xNo.,oOc;kx::kNMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMWKo;cONMx,kMXl.;l;:KMMWk;,ldxxxxo:'cKWMMk,:l''xWNl;0MXx::kNMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMWKo;cONMMMx,kMNo,lc.;OWXo;oKWMMMMMMNOc;kWWd',o:,kMNl;0MMMXx::kNMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMXocONMMMMMx,kMWo,OK:.;ol,dWMMMMMMMMMMXc;dl'.dWd,kMNl;0MMMMMXx:kMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMWXNMMMMMMMx,kMWo,OXc,dc',kWMMMMMMMMMMNo.,ol,xWd,kMNl;0MMMMMMMNNMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMNl,OX:;KXc.;dKWMMMMMMWOl''xWk,xWd,kMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMWl,OX:;KNl,oc;dKWMMWOc;oc,kMk,xWd,kMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMWl,OXc;KNl;0NOc;dkxl;oKWx,kMk,xWd,kMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMWo,OX:;KNl;0MMXl'..,xWMMx,kMk,xWd,kMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMWl,OX:;KNl;0WOl;oxdc;oKWx,kMk,xWd,kMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMWkkNX:;KNl,ol;oKWMMNOc;ol,kMk,dWKx0MNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMMMMMX:;KXc.,oKWMMMMMMNOc''xWk,dMMMMMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMMMMMXc;dl',kWMMMMMMMMMMNo.,dl,xMMMMMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMMMMMXc.;l;;0MMMMMMMMMMMMx'cc''xMMMMMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMMMMMXooKNl;0MMMMMMMMMMMMx,kNOlkWMMMMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMx,kMMMMMWNWMNl;0MMMMMMMMMMMMx,kMMWWMMMMMNl;0MMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMM0xXMMMMMMMMMNl;0MMMMMMMMMMMMx,kMMMMMMMMMM0xKMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMNkkNMMMMMMMMMMMMKd0MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMM[ https://www.youtube.com/watch?v=SrxOL_j-o88 ]MMMMMMMMMMMMMMMMMMMMMMMMMMM`
